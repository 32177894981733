globalThis["__sentryRewritesTunnelPath__"] = undefined;
globalThis["SENTRY_RELEASE"] = {"id":"2fe2a9bcf017b76e95ea6c01ce98962fa1587ede"};
globalThis["__sentryBasePath"] = undefined;
globalThis["__rewriteFramesAssetPrefixPath__"] = "";
// This file configures the initialization of Sentry on the browser.
// The config you add here will be used whenever a page is visited.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import * as Sentry from '@sentry/nextjs';
import {
  standardSentryConfig,
  extraClientConfig,
} from '@mentimeter/sentry-config';

const environmentName = process.env.NEXT_PUBLIC_ENVIRONMENT_NAME ?? 'dev';
const shouldHavePerformanceMonitor = environmentName === 'prod';

Sentry.init({
  dsn: 'https://0f6945da36d5073846961be1f39e1d78@o866780.ingest.us.sentry.io/4507186403540992',
  ...standardSentryConfig,
  ...extraClientConfig,
  tracesSampleRate: shouldHavePerformanceMonitor ? 0.03 : 0,
  integrations: [Sentry.browserTracingIntegration()],
});
